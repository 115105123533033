import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import * as PropTypes from "prop-types";
import {
  checkErrorPassword,
  checkErrorUsername,
  getHelperTextUserName,
} from "../lib/login";

export default function Login(props) {
  const handleClose = () => {
    setUsername("");
    setPassword("");
    props.close();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    props.submit(username, password);
    handleClose();
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  return (
    <Dialog
      open={props.is_open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      style={{ zIndex: 10000 }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">Login</DialogTitle>
        <DialogContent>
          <TextField
            error={checkErrorUsername(username)}
            helperText={getHelperTextUserName(username)}
            autoFocus
            margin="dense"
            id="username"
            label="아이디를 입력해주세요."
            type="text"
            required={true}
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="password"
            error={checkErrorPassword(password)}
            label="비밀번호를 입력해주세요."
            type="password"
            required={true}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => {}} color="primary" type="submit">
            Login
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

Login.propTypes = {
  is_open: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
};
