export const fail = (message) => {
  return {
    type: "OPEN_FAIL",
    open: true,
    message: message,
  };
};
export const ok = (message) => {
  return {
    type: "OPEN_SUCCESS",
    open: true,
    message: message,
  };
};

export const openAccount = () => {
  return { type: "OPEN_ACCOUNT" };
};

export const closeAccount = () => {
  return { type: "CLOSE_ACCOUNT" };
};
