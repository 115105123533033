import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import * as PropTypes from "prop-types";

export default function SignUp(props) {
  const handleClose = () => {
    setUsername("");
    setEmail("");
    setPassword("");
    setPasswordConfirm("");
    props.close();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    props.submit(username, email, password, passwordConfirm);
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [email, setEmail] = useState("");
  return (
    <Dialog
      open={props.is_open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      style={{ zIndex: 10000 }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">Sign Up</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="name"
            type="text"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="email"
            label="email"
            type="text"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="password"
            label="password"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="password_confirm"
            label="password confirm"
            type="password"
            value={passwordConfirm}
            onChange={(e) => {
              setPasswordConfirm(e.target.value);
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Signup
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

SignUp.propTypes = {
  is_open: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
};
