import React, { lazy, Suspense, useEffect, useState } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { getJwtToken, parseJwt, refreshToken } from "./lib/jwtToken";
import Login from "./components/Login.container";
import Signup from "./components/Signup.container";
import Profile from "./components/Profile.contianer";
import CreateMap from "./components/MyMaps/CreateMap.container";
import Fail from "./components/feedback/Fail";
import NavAccount from "./components/NavAccount";
import { close_snackbar_dialog, set_user_marking } from "./redux/map.action";
import isBeta from "./lib/beta";
import { get, post } from "./lib/axios";
import Loading from "./components/Loading";
import { fail } from "./redux/global.action";

const Map = lazy(() => import("./components/map/Map"));
const Home = lazy(() => import("./components/Home"));

function App(props) {
  useEffect(() => {
    /* get('/profile/', null, (result) => {
                props.loginStatus(result.data.is_login, result.data.username, result.data.userid);
            }); */
    getJwtToken().then((jwtToken) => {
      if (jwtToken) {
        refreshToken(jwtToken).then((newJwtToken) => {
          if (newJwtToken) {
            props.loginStatus(jwtToken);
            get("/api/v1/marking_by_member/", null, (result) => {
              props.marking(result.data);
            });
          }
        });
      }
    });
  }, []);
  return (
    <div className="App">
      <MyAccount />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/about" />
          <Route path={["/map/:map_id/:node_id", "/map/:map_id"]}>
            <Map />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Suspense>
      <Login />
      <Signup />
      <Profile />
      {isBeta() ? <NavAccount /> : ""}
      <CreateMap />
      <Fail />
      <Snackbar
        open={props.snackbar_is_open}
        autoHideDuration={props.snackbar_auto_hide_duration}
        onClose={() => {
          props.close_snackbar();
        }}
      >
        <Alert severity="success">{props.snackbar_message}</Alert>
      </Snackbar>
    </div>
  );
}

function MyAccount() {
  const is_open = useSelector((state) =>
    state.getIn(["ui", "my_account", "is_open"])
  );
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [oldPasswordError1, setOldPasswordError1] = useState(null);
  const [newPasswordError1, setNewPasswordError1] = useState(null);
  const [newPasswordError2, setNewPasswordError2] = useState(null);
  const dispatch = useDispatch();
  return (
    <Dialog open={is_open} style={{ zIndex: 10000000 }}>
      <DialogTitle>계정정보</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="이전 비밀번호"
          type="password"
          fullWidth
          onChange={(evt) => {
            setOldPassword(evt.target.value);
          }}
          value={oldPassword}
          error={oldPasswordError1 !== null}
          helperText={oldPasswordError1 === null ? null : oldPasswordError1}
        />
        <TextField
          autoFocus
          margin="dense"
          label="새로운 비밀번호"
          type="password"
          fullWidth
          onChange={(evt) => {
            setNewPassword1(evt.target.value);
          }}
          value={newPassword1}
          error={newPasswordError1 !== null}
          helperText={newPasswordError1 === null ? null : newPasswordError1}
        />
        <TextField
          autoFocus
          margin="dense"
          label="새로운 비밀번호 확인"
          type="password"
          fullWidth
          onChange={(evt) => {
            setNewPassword2(evt.target.value);
          }}
          value={newPassword2}
          error={newPasswordError2 !== null}
          helperText={newPasswordError2 === null ? null : newPasswordError2}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            dispatch({ type: "CLOSE_MY_ACCOUNT" });
          }}
        >
          닫기
        </Button>
        <Button
          color="primary"
          onClick={() => {
            if (oldPassword === "") {
              setOldPasswordError1("비밀번호가 입력되지 않았습니다");
              return;
            }
            setOldPasswordError1(null);

            if (newPassword1 === "") {
              setNewPasswordError1("비밀번호가 입력되지 않았습니다");
              return;
            }
            setNewPasswordError1(null);

            if (newPassword2 === "") {
              setNewPasswordError2("비밀번호가 입력되지 않았습니다");
              return;
            }
            setNewPasswordError2(null);

            if (newPassword1 !== newPassword2) {
              setNewPasswordError1("입력한 비밀번호가 일치하지 않습니다");
              setNewPasswordError2("입력한 비밀번호가 일치하지 않습니다");
              return;
            }
            setNewPasswordError1(null);
            setNewPasswordError2(null);

            post(
              "/api/v1/member/changePassword/",
              {
                old_password: oldPassword,
                new_password: newPassword1,
              },
              (res) => {
                if (res.data.result === "success") {
                  dispatch({ type: "CLOSE_MY_ACCOUNT" });
                }
                console.log("res", res.data.result);
              },
              (err) => {
                // dispatch({type:'CLOSE_MY_ACCOUNT'});
                if (
                  err.response.data.old_password[0] === "invalid old_password"
                ) {
                  dispatch(fail("기존의 비밀번호가 일치하지 않습니다."));
                }
              }
            );
          }}
        >
          변경
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default connect(
  (state) => ({
    snackbar_is_open: state.getIn(["ui", "snackbar", "is_open"]),
    snackbar_message: state.getIn(["ui", "snackbar", "message"]),
    snackbar_auto_hide_duration: state.getIn([
      "ui",
      "snackbar",
      "auto_hide_duration",
    ]),
  }),
  (dispatch) => ({
    loginStatus: (jwtToken) => {
      const jwtBody = parseJwt(jwtToken);
      dispatch({
        type: "SET_LOGIN_STATUS",
        value: true,
        username: jwtBody.username,
        userid: jwtBody.user_id,
        email: jwtBody.email,
      });
      dispatch({ type: "JWT_TOKEN", value: jwtToken });
    },
    marking: (marked) => {
      dispatch(set_user_marking(marked));
    },
    close_snackbar: () => {
      dispatch(close_snackbar_dialog());
    },
  })
)(App);
