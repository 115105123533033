import axios from "axios";
import { fail } from "../redux/global.action";
import store from "../redux/store";
import { getJwtToken } from "./jwtToken";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

const withCredentials = false;

export let apiHost = "http://localhost:8000"; // dev

if (process.env.REACT_APP_API_HOST) {
  if (process.env.REACT_APP_API_HOST === "self") {
    apiHost = window.location.protocol + "//" + window.location.host;
  } else {
    apiHost = process.env.REACT_APP_API_HOST;
  }
}

// export const apiHost = location.protocol + '//' + location.host; // real
export default axios;

export async function post(url, data, success, failFn) {
  const jwtToken = await getJwtToken();
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencode",
      Authorization: jwtToken ? `jwt ${jwtToken}` : "",
    },
    withCredentials,
  };
  var bodyFormData = new FormData();
  for (var name in data) {
    bodyFormData.set(name, data[name]);
  }
  axios
    .post(apiHost + url, bodyFormData, config)
    .then((res) => {
      success(res);
    })
    .catch((err) => {
      if (failFn) failFn(err);
      else
        store.dispatch(
          fail(
            `네트워크 오류가 발생했습니다. method:POST url: ${url}, data: ${JSON.stringify(
              data
            )}`
          )
        );
    });
}

export async function patch(url, data, success, failFn) {
  const jwtToken = await getJwtToken();
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencode",
      Authorization: jwtToken ? `jwt ${jwtToken}` : "",
    },
    withCredentials,
  };
  var bodyFormData = new FormData();
  for (var name in data) {
    bodyFormData.set(name, data[name]);
  }
  axios
    .patch(apiHost + url, bodyFormData, config)
    .then((res) => {
      success(res);
    })
    .catch((err) => {
      if (failFn) failFn(err);
      else
        store.dispatch(
          fail(
            `네트워크 오류가 발생했습니다. method:PATCH url: ${url}, data: ${JSON.stringify(
              data
            )}`
          )
        );
    });
}

export async function get(url, data, success, failFn) {
  const jwtToken = await getJwtToken();
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencode",
      Authorization: jwtToken ? `jwt ${jwtToken}` : "",
    },
    withCredentials,
  };
  axios
    .get(apiHost + url, config)
    .then((res) => {
      if (success) success(res);
    })
    .catch((err) => {
      if (failFn) failFn(err);
      else if ([401, 403, 404].includes(err.response?.status)) {
        store.dispatch(fail("존재하지 않거나 접근할 수 없는 지도입니다."));
      } else {
        store.dispatch(
          fail(
            `네트워크 오류가 발생했습니다. method:GET url: ${url}, data: ${JSON.stringify(
              data
            )}`
          )
        );
      }
    });
}

export async function _delete(url, data, success, failFn) {
  const jwtToken = await getJwtToken();
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencode",
      Authorization: jwtToken ? `jwt ${jwtToken}` : "",
    },
    withCredentials,
  };
  axios
    .delete(apiHost + url, Object.assign(config, { data }))
    .then((res) => {
      if (success) success(res);
    })
    .catch((err) => {
      if (failFn) failFn(err);
      else
        store.dispatch(
          fail(
            `네트워크 오류가 발생했습니다. method:GET url: ${url}, data: ${JSON.stringify(
              data
            )}`
          )
        );
    });
}
