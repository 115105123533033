import CircularProgress from "@material-ui/core/CircularProgress";

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100vw",
};
export default function Loading() {
  return (
    <>
      <div style={style}>
        <CircularProgress></CircularProgress>
      </div>
    </>
  );
}
