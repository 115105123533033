import { Map } from "immutable";

const init = Map({
  MyMaps: {
    list: [],
    CreateMap: {
      is_open: false,
    },
  },
  ui: {
    my_account: {
      is_open: false,
    },
    login_dialog_is_open: false,
    signup_dialog_is_open: false,
    profile_dialog_is_open: false,
    map_drawer_is_open: false,
    map_sub_drawer_is_open: true,
    edit_node_is_open: false,
    map_setting: {
      is_open: false,
      selected_tab_id: 1,
      add_collaborator: {
        is_open: false,
      },
    },
    create_node_is_open: false,
    create_node_connect_type: "root",
    create_node_target: null,
    create_node_position: { x: 0, y: 0 },
    fail_is_open: false,
    fail_message: null,
    connect_edge: { open: false, source: null },
    account_is_open: false,
    cursor_last_tab_position: null,
    tooltip: {
      is_open: false,
      pos: { x: 0, y: 0 },
      id: null,
      url: null,
      label: null,
      countMarking: {},
    },
    snackbar: {
      is_open: false,
      message: "null",
      auto_hide_duration: 0,
    },
    lastVisit: {
      is_open: false,
      id: null,
      name: null,
    },
  },
  user: {
    is_login: false,
    username: null,
    userid: null,
    email: null,
    marking: [],
  },
  map: {
    selected_map_id: null,
    elements: [],
    selected_node: {
      id: null,
      is_need_animation: false,
    },
    permission: {
      read_public: false,
      write_public: false,
    },
    name: "",
  },
});

const mapReducer = (state = [], action) => {
  var newState = init;
  switch (action.type) {
    case "CREATE_MAP_OPEN":
      newState = state.setIn(["MyMaps", "CreateMap", "is_open"], true);
      break;
    case "CREATE_MAP_CLOSE":
      newState = state.setIn(["MyMaps", "CreateMap", "is_open"], false);
      break;
    case "CREATE_MAP_SUCCESS":
      newState = state.setIn(["MyMaps", "CreateMap", "is_open"], false);
      break;
    case "SET_MYMAP_LIST":
      newState = state.setIn(["MyMaps", "list"], action.value);
      break;
    case "SET_PUBLIC_MAP_LIST":
      newState = state.setIn(["PublicMaps", "list"], action.value);
      break;
    case "SET_LOGIN_STATUS":
      newState = state.setIn(["user", "is_login"], action.value);
      newState = newState.setIn(
        ["user", "username"],
        action.username ? action.username : null
      );
      newState = newState.setIn(
        ["user", "userid"],
        action.userid ? action.userid : null
      );
      newState = newState.setIn(
        ["user", "email"],
        action.email ? action.email : null
      );
      break;
    case "SET_USER_MARKING":
      newState = state.setIn(["user", "marking"], action.marked);
      break;
    case "CHANGE_MARKING_NUMBER":
      newState = state.setIn(
        ["ui", "tooltip", "countMarking", "saw"],
        action.newMarked
      );
      break;
    case "OPEN_LOGIN_DIALOG":
      newState = state.setIn(["ui", "login_dialog_is_open"], true);
      break;
    case "CLOSE_LOGIN_DIALOG":
      newState = state.setIn(["ui", "login_dialog_is_open"], false);
      break;
    case "OPEN_SIGNUP_DIALOG":
      newState = state.setIn(["ui", "signup_dialog_is_open"], true);
      break;
    case "CLOSE_SIGNUP_DIALOG":
      newState = state.setIn(["ui", "signup_dialog_is_open"], false);
      break;
    case "OPEN_PROFILE_DIALOG":
      newState = state.setIn(["ui", "profile_dialog_is_open"], true);
      break;
    case "CLOSE_PROFILE_DIALOG":
      newState = state.setIn(["ui", "profile_dialog_is_open"], false);
      break;
    case "SET_MAP":
      if (action.value !== undefined)
        newState = state.setIn(["map", "elements"], action.value);
      if (action.members !== undefined)
        newState = newState.setIn(["map", "members"], action.members);
      if (action.selected_map_id !== undefined)
        newState = newState.setIn(
          ["map", "selected_map_id"],
          action.selected_map_id
        );
      if (action.permission !== undefined) {
        newState = newState.setIn(["map", "permission"], action.permission);
      }
      if (action.name !== undefined) {
        newState = newState.setIn(["map", "name"], action.name);
      }
      break;
    case "CHANGE_MAP_SETTING":
      newState = state.setIn(["map", "name"], action.name);
      newState = newState.setIn(["map", "permission"], action.permission);
      break;
    case "CHANGE_MAP_SETTING_TAB_ID":
      newState = state.setIn(
        ["ui", "map_setting", "selected_tab_id"],
        action.id
      );
      break;
    case "OPEN_MAP_DRAWER":
      newState = state.setIn(["ui", "map_drawer_is_open"], true);
      break;
    case "CLOSE_MAP_DRAWER":
      newState = state.setIn(["ui", "map_drawer_is_open"], false);
      break;
    case "OPEN_MAP_SUB_DRAWER":
      newState = state.setIn(["ui", "map_sub_drawer_is_open"], true);
      break;
    case "CLOSE_MAP_SUB_DRAWER":
      newState = state.setIn(["ui", "map_sub_drawer_is_open"], false);
      break;
    case "OPEN_CREATE_NODE":
      newState = state.setIn(["ui", "create_node_is_open"], true);
      newState = newState.setIn(
        ["ui", "create_node_connect_type"],
        action.connect_type
      );
      newState = newState.setIn(["ui", "create_node_target"], action.target);
      newState = newState.setIn(
        ["ui", "create_node_position"],
        action.position
      );
      break;
    case "OPEN_EDIT_NODE":
      newState = state.setIn(["ui", "edit_node_is_open"], true);
      newState = newState.setIn(["ui", "edit_node_target"], action.target);
      newState = newState.setIn(["ui", "edit_node_position"], action.position);
      break;
    case "CLOSE_CREATE_NODE":
      newState = state.setIn(["ui", "create_node_is_open"], false);
      break;
    case "CLOSE_EDIT_NODE":
      newState = state.setIn(["ui", "edit_node_is_open"], false);
      break;
    case "OPEN_FAIL":
      newState = state.setIn(["ui", "fail_is_open"], action.open);
      newState = newState.setIn(["ui", "fail_message"], action.message);
      break;
    case "CLOSE_FAIL":
      newState = state.setIn(["ui", "fail_is_open"], false);
      newState = newState.setIn(["ui", "fail_message"], null);
      break;
    case "SELECT_NODE":
      newState = state.setIn(["map", "selected_node"], action.selected_node);
      break;
    case "CONNECT_EDGE":
      newState = state.setIn(["ui", "connect_edge"], {
        open: true,
        source: action.source,
      });
      break;
    case "CONNECT_ABORT":
      newState = state.setIn(["ui", "connect_edge"], {
        open: false,
        source: null,
      });
      break;
    case "OPEN_ACCOUNT":
      newState = state.setIn(["ui", "account_is_open"], true);
      break;
    case "CLOSE_ACCOUNT":
      newState = state.setIn(["ui", "account_is_open"], false);
      break;
    case "JWT_TOKEN":
      newState = state.setIn(["user", "jwtToken"], action.value);
      break;
    case "TAB_POSITION":
      newState = state.setIn(
        ["ui", "cursor_last_tab_position"],
        action.position
      );
      break;
    case "OPEN_TOOLTIP":
      newState = state.setIn(["ui", "tooltip", "is_open"], true);
      newState = newState.setIn(["ui", "tooltip", "id"], action.id);
      newState = newState.setIn(["ui", "tooltip", "url"], action.url);
      newState = newState.setIn(["ui", "tooltip", "label"], action.label);
      newState = newState.setIn(["ui", "tooltip", "pos"], action.pos);
      if (action.countMarking !== undefined) {
        newState = newState.setIn(
          ["ui", "tooltip", "countMarking"],
          action.countMarking
        );
      }
      break;
    case "OPEN_SNACKBAR_DIALOG":
      newState = state.setIn(["ui", "snackbar", "is_open"], true);
      newState = newState.setIn(["ui", "snackbar", "message"], action.message);
      newState = newState.setIn(
        ["ui", "snackbar", "auto_hide_duration"],
        action.auto_hide_duration ? action.auto_hide_duration : 6000
      );
      break;
    case "CLOSE_SNACKBAR_DIALOG":
      newState = state.setIn(["ui", "snackbar", "is_open"], false);
      break;
    case "CLOSE_MAP_SETTING":
      newState = state.setIn(["ui", "map_setting", "is_open"], false);
      break;
    case "OPEN_MAP_SETTING":
      newState = state.setIn(["ui", "map_setting", "is_open"], true);
      break;
    case "OPEN_MAP_SETTING_ADD_COLLABORATOR":
      newState = state.setIn(
        ["ui", "map_setting", "add_collaborator", "is_open"],
        true
      );
      break;
    case "CLOSE_MAP_SETTING_ADD_COLLABORATOR":
      newState = state.setIn(
        ["ui", "map_setting", "add_collaborator", "is_open"],
        false
      );
      break;
    case "SET_TOOLTIP_META":
      newState = state.setIn(
        ["ui", "tooltip", "meta", "image"],
        action.meta.image
      );
      newState = newState.setIn(
        ["ui", "tooltip", "meta", "title"],
        action.meta.title
      );
      newState = newState.setIn(
        ["ui", "tooltip", "meta", "description"],
        action.meta.description
      );
      newState = newState.setIn(
        ["ui", "tooltip", "meta", "point"],
        action.meta.point
      );
      newState = newState.setIn(
        ["ui", "tooltip", "meta", "count"],
        action.meta.count
      );
      break;
    case "CLOSE_TOOLTIP":
      newState = state.setIn(["ui", "tooltip", "is_open"], false);
      break;
    case "OPEN_LAST_VISIT":
      newState = state.setIn(["ui", "lastVisit", "is_open"], true);
      newState = newState.setIn(["ui", "lastVisit", "name"], action.name);
      newState = newState.setIn(["ui", "lastVisit", "id"], action.id);
      break;
    case "OPEN_MY_ACCOUNT":
      newState = state.setIn(["ui", "my_account", "is_open"], true);
      break;
    case "CLOSE_MY_ACCOUNT":
      newState = state.setIn(["ui", "my_account", "is_open"], false);
      break;
    default:
  }
  return newState;
};

export default mapReducer;
