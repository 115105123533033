import Profile from "./Profile";
import { connect } from "react-redux";

export default connect(
  (state) => {
    return {
      is_open: state.getIn(["ui", "profile_dialog_is_open"]),
      profile: state.getIn(["user"]),
    };
  },
  (dispatch) => {
    return {
      close: () => {
        dispatch({ type: "CLOSE_PROFILE_DIALOG" });
      },
    };
  }
)(Profile);
